<template>
  <n-modal v-model:show="showModal">
    <n-card
      :style="`width: ${isMobile ? (windowsWidth * 80) / 100 : 600}px`"
      :title="t('commons.labels.review', 2)"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <template #header-extra>
        <n-button circle size="small" @click="showModal = false">
          <template #icon>
            <close-icon />
          </template>
        </n-button>
      </template>
      <n-scrollbar :style="`max-height: ${windowsHeight / 2}px`">
        <n-space vertical>
          <span v-for="review in reviewsRef" :key="review.id">
            <n-thing>
              <template #avatar>
                <n-avatar
                  :style="{
                    backgroundColor: 'white',
                  }"
                  class="avatar"
                  :size="36"
                >
                  <n-icon :color="themeOverrides.common.primaryColor">
                    <PersonCircleOutline />
                  </n-icon>
                </n-avatar>
              </template>
              <template #header>{{ getUser(review.from).name }} </template>

              <template #description v-if="reviewsRef.length > 0">
                {{ formatDate(review.datetime) }}</template
              >
            </n-thing>

            <n-rate
              :value="isForToolReviews ? review.toolRate : review.userRate"
              readonly
            />
            <br />
            <span>{{ isForToolReviews ? review.toolReview : review.userReview }}</span>
          </span></n-space
        ></n-scrollbar
      >
    </n-card>
  </n-modal>
</template>

<script>
import { defineComponent, toRefs, watch, ref } from "vue";
import {
  NButton,
  NAvatar,
  NIcon,
  NCard,
  NModal,
  NRate,
  NThing,
  NSpace,
  NScrollbar,
} from "naive-ui";
import responsive from "@/mixins/responsive";
import { PersonCircleOutline, Close as CloseIcon } from "@vicons/ionicons5";
import { format, parseISO } from "date-fns";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

const DATE_FORMAT = "MMM yyyy";

export default defineComponent({
  name: "ModalReviews",
  props: {
    reviews: { type: Array, required: true },
    users: { type: Array, required: true },
    show: { type: Boolean, required: true },
    isForToolReviews: { type: Boolean, required: false, default: false },
  },
  components: {
    NButton,
    NAvatar,
    NIcon,
    PersonCircleOutline,
    CloseIcon,
    NCard,
    NModal,
    NRate,
    NThing,
    NSpace,
    NScrollbar,
  },
  mixins: [responsive],
  emits: ["update:show"],
  data() {
    return {
      themeOverrides,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    //Controles UI
    const showModalRefRO = toRefs(props).show;
    const showModalRef = ref(false);
    //Referencias
    const reviewsRef = toRefs(props).reviews;
    const usersRef = toRefs(props).users;

    watch(showModalRef, (newValue) => {
      if (newValue === false) {
        emit("update:show", newValue);
      }
    });

    watch(showModalRefRO, (newValue) => {
      showModalRef.value = newValue;
    });

    return {
      t,
      getUser: (id) => usersRef.value.find((user) => user.id === id),
      reviewsRef,
      showModal: showModalRef,
      formatDate: (date) => format(parseISO(date), DATE_FORMAT),
    };
  },
});
</script>

<style scoped>
.avatar {
  color: v-bind(themeOverrides.common.successColor);
  backgroundcolor: v-bind(themeOverrides.common.whiteColor);
}
</style>
