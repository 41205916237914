<template>
  <n-space v-if="publicationRef" :vertical="vertical" align="center">
    <n-statistic v-if="showTitle">
      <template #suffix>{{ publicationRef.title }}</template>
    </n-statistic>
    <img
      v-if="showDefaultImage"
      width="120"
      :src="publicationRef.defaultImageUrl"
      alt="tool image"
    />
    <br v-if="isMobile" />
    <n-card
      v-if="showReviewInfo || showLocation || showDeliveryInfo || showPrice"
      :bordered="bordered"
    >
      <n-space v-if="showReviewInfo && reviews.length > 0" justify="end"
        ><n-button text @click="showReviewsModal = true">
          <template #icon>
            <n-icon>
              <StarFilled />
            </n-icon>
          </template>
          {{ rate }} ({{ reviews.length }}
          {{ t("publications.resume.labels.review", reviews.length) }})
        </n-button>
      </n-space>
      <span id="Location" v-if="showLocation"
        ><n-statistic>
          <template #prefix>
            <n-icon :color="themeOverrides.common.primaryColor">
              <Location />
            </n-icon>
          </template>
          <template #suffix
            >{{ publicationRef.locationInfo?.municipality }},
            {{ publicationRef.locationInfo?.region }}</template
          >
        </n-statistic>
      </span>
      <span id="DeliveryInfo" v-if="showDeliveryInfo"
        ><n-statistic>
          <template #prefix>
            <n-icon :color="themeOverrides.common.primaryColor">
              <DeliveryTruck v-if="publicationRef.haveDelivery" />
              <Information v-else />
            </n-icon>
          </template>
          <template #suffix>{{
            !publicationRef.haveDelivery
              ? t("publications.resume.labels.deliveryTypes.pickup")
              : t("publications.resume.labels.deliveryTypes.delivery")
          }}</template>
        </n-statistic></span
      >
      <span id="Prices" v-if="showPrice"
        ><n-statistic
          :value="
            floatToCurrency(
              publicationRef.prices?.pricePerDay,
              t('commons.labels.day', 1)
            ).replace(t('commons.financial.currencySymbol'), '')
          "
        >
          <template #prefix>
            <n-icon :color="themeOverrides.common.primaryColor">
              <CurrencyDollar />
            </n-icon>
          </template>
        </n-statistic>
        <n-alert
          v-if="publicationRef.prices?.offerDiscount && showSpecialOffers"
          :title="t('publications.resume.labels.specialOffers')"
          type="info"
        >
          <template #icon>
            <n-icon :color="themeOverrides.common.primaryColor">
              <CheckmarkFilled />
            </n-icon>
          </template>
          <div>
            <n-space vertical>
              <div v-if="publicationRef.prices.weeklyDiscount > 0" class="pt-2">
                {{
                  floatToCurrency(
                    publicationRef.prices?.discountedPricePerDayForWeeks,
                    t("commons.labels.day", 1)
                  )
                }}
                {{ t("publications.resume.labels.discounts.perWeeks") }}
              </div>
              <div v-if="publicationRef.prices.monthlyDiscount > 0" class="pt-2">
                {{
                  floatToCurrency(
                    publicationRef.prices?.discountedPricePerDayForMonths,
                    t("commons.labels.day", 1)
                  )
                }}
                {{ t("publications.resume.labels.discounts.perMonths") }}
              </div></n-space
            >
          </div>
        </n-alert></span
      >
    </n-card></n-space
  >
  <ModalReviews
    :reviews="reviews"
    :users="reviewUsers"
    v-model:show="showReviewsModal"
    isForToolReviews
  />
</template>

<script>
import { defineComponent, toRefs, watch, onMounted, ref, computed } from "vue";
import { floatToCurrency, round } from "@/shared/utils";
import {
  CheckmarkFilled,
  CurrencyDollar,
  DeliveryTruck,
  Information,
  Location,
  StarFilled,
} from "@vicons/carbon";
import { NAlert, NSpace, NCard, NStatistic, NIcon, NButton } from "naive-ui";
import responsive from "@/mixins/responsive";
import { Storage } from "aws-amplify";
import { useStore } from "vuex";
import ModalReviews from "@/components/ModalReviews.vue";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "PublicacionResumen",
  props: {
    publication: { type: Object, required: true },
    bordered: { type: Boolean, required: false, default: false },
    showDefaultImage: { type: Boolean, required: false, default: false },
    showLocation: { type: Boolean, required: false, default: false },
    showDeliveryInfo: { type: Boolean, required: false, default: false },
    showPrice: { type: Boolean, required: false, default: false },
    vertical: { type: Boolean, required: false, default: false },
    showTitle: { type: Boolean, required: false, default: true },
    showSpecialOffers: { type: Boolean, required: false, default: true },
    showReviewInfo: { type: Boolean, required: false, default: false },
  },
  components: {
    DeliveryTruck,
    CheckmarkFilled,
    CurrencyDollar,
    Information,
    Location,
    StarFilled,
    NAlert,
    NSpace,
    NCard,
    NStatistic,
    NIcon,
    NButton,
    ModalReviews,
  },
  mixins: [responsive],
  data() {
    return {
      themeOverrides,
    };
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const publicationRef = toRefs(props).publication;
    const reviewsRef = ref([]);
    const reviewUsersRef = ref([]);
    const showReviewsModalRef = ref(false);

    const loadReviews = async () => {
      console.debug("loadReviews ", publicationRef.value);
      reviewsRef.value = await store.dispatch(
        "review/getReviewByPublication",
        publicationRef.value.id
      );

      const usersId = [];
      for (const review of reviewsRef.value) {
        if (!usersId.includes(review.from)) {
          usersId.push(review.from);
        }
      }

      reviewUsersRef.value = await store.dispatch("review/getUsersById", usersId);
    };
    onMounted(() => {
      if (
        props.showDefaultImage &&
        publicationRef.value.orderPhotos?.length > 0 &&
        !publicationRef.value.defaultImageUrl
      ) {
        Storage.get(
          `publication/${publicationRef.value.id}/${publicationRef.value.orderPhotos[0]}`,
          {
            level: "protected",
            identityId: publicationRef.value.user,
          }
        ).then((image) => {
          publicationRef.value.defaultImageUrl = image;
        });
      }
      if (props.showReviewInfo) {
        loadReviews();
      }
    });

    watch(publicationRef, (newPublicationRef) => {
      if (
        props.showDefaultImage &&
        publicationRef.value.orderPhotos?.length > 0 &&
        !newPublicationRef.defaultImageUrl
      ) {
        Storage.get(
          `publication/${publicationRef.value.id}/${publicationRef.value.orderPhotos[0]}`,
          {
            level: "protected",
            identityId: publicationRef.value.user,
          }
        ).then((image) => {
          newPublicationRef.defaultImageUrl = image;
        });
      }
    });
    return {
      t,
      publicationRef,
      floatToCurrency,
      reviews: reviewsRef,
      reviewUsers: reviewUsersRef,
      showReviewsModal: showReviewsModalRef,
      rate: computed(() =>
        round(
          reviewsRef.value.reduce((partial, review) => partial + review.userRate, 0) /
            reviewsRef.value.length
        )
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.n-card {
  text-align: left;
  max-width: 330px;
  height: 100%;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
}

:deep(.n-card-header) {
  padding-bottom: 0px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
